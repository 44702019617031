$(document).ready(function () {
    let urlcontributors = '//img.'+ getDomain() +'medscapestatic.com/medscape-files/dp-hub/dpContributors.json';
    $.ajax({
        url: urlcontributors,
        type: 'get',
        async: false,
        success: function (data) {
            addContributors(data);
        }
    });
    let urlcards = '//img.'+ getDomain() +'medscapestatic.com/medscape-files/dp-hub/dpCards.json';
    $.ajax({
        url: urlcards,
        type: 'get',
        async: false,
        success: function (data) {
            dpCards(data);
        }
    });

});

function addContributors(contibutors) {
    for (const obj of contibutors) {
        var _contriHtmlFirstPart = `<div class="contributors"><div class=contributors-speciality>${obj['title']}</div><div class="contributors-section">`;
        var _contriHtmlSecondPart = '';
        if (obj['contributors'].length > 0) {
            const _contributor = obj['contributors'];
            for (const iterator of _contributor) {
                let contributorHtml = "";
                if (iterator.expertTitle) {
                    contributorHtml = `<div class="contributor">
                                <img src=${iterator.profilePic} class="headshot" alt=${iterator.expertName}> 
                                    <div class="expert">
                                        ${iterator.expertName}
                                        <div class="expert-title">
                                            ${iterator.expertTitle}
                                        </div>
                                    </div>
                                </div>`;
                } else {
                    contributorHtml = `<div class="contributor">
                                <img src=${iterator.profilePic} class="headshot" alt=${iterator.expertName}> 
                                    <div class="expert">
                                        ${iterator.expertName}
                                    </div>
                                </div>`;
                }
                _contriHtmlSecondPart += contributorHtml;
            }
        }
        _contriHtmlFirstPart = _contriHtmlFirstPart + _contriHtmlSecondPart + '</div></div>';
        $(".decision-hub-contributors-section").append(_contriHtmlFirstPart);
    }
}

function dpCards(cardsJson) {
    for (let key in cardsJson) {
        if ($(`#${key}`).length > 0) {
            // $(`#${key} .decision-hub-cards-title`).after(`<div class="decision-hub-cards-text">
            //     ${cardsJson[key].desc}
            // </div>`);
            if (!cardsJson[key].active) {
                $(`#${key}`).addClass("disabled");
            }
        }
    }
    alphaOrder_Cards();
}
function alphaOrder_Cards(){
    let hubCards = $(".decision-hub-container .decision-hub-cards");
    if(hubCards.length > 0){
        let ordered = hubCards.sort(function(card1,card2){ 
            return $(card1).text().toLowerCase() < $(card2).text().toLowerCase()? -1 :1; 
        })
        $(".decision-hub-container").html(ordered);
    }
}